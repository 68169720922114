<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ header }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div
          v-if="scanningActionType !== 'tote'"
          class="bg-secondary rounded p-6 mb-3"
        >
          <v-row>
            <v-col v-if="scanningType !== 'tote'" cols="auto">
              <v-row class="bg-secondary rounded mt-0">
                <v-col cols="auto" class="font-weight-black py-0 pr-0">
                  SKU:
                </v-col>
                <v-col v-if="sku" class="py-0">{{ sku }}</v-col>
              </v-row>
            </v-col>
            <v-col v-if="scanningType !== 'tote'" cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Description:
                </v-col>
                <v-col>{{ skuDescription }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="bg-secondary rounded p-6 mb-8">
          <v-row>
            <v-col cols="auto">
              <v-row v-if="putawayTaskItemData">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Tote:
                </v-col>
                <v-col>
                  <div v-if="tote">
                    {{ tote }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row v-if="putawayTaskItemData">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Suggested Location:
                </v-col>
                <v-col>
                  <div v-if="suggestedLocation">
                    {{ suggestedLocation.name }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="scanningType !== 'tote'" cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Expected Quantity:
                </v-col>
                <v-col v-if="quantity !== null">{{ quantity }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div>
          <div>
            <v-row>
              <v-col cols="12">
                <input-field
                  ref="tote"
                  id="tote_id"
                  @update-value="updateValue"
                  type="textfield"
                  label="Tote"
                  required
                  :onError="formError.tote_id"
                  :onEnter="handleToteInput"
                />
                <input-field
                  v-if="scanningType !== 'tote'"
                  ref="sku"
                  id="sku"
                  @update-value="updateValue"
                  type="textfield"
                  label="SKU"
                  required
                  :disabled="isDisabled.sku"
                  :onError="formError.sku"
                  :onEnter="handleSkuInput"
                />
                <input-field
                  v-if="scanningType !== 'tote'"
                  ref="quantity"
                  id="qty"
                  @update-value="updateValue"
                  type="textfield"
                  label="Quantity"
                  required
                  :default="0"
                  number
                  :disabled="
                    isDisabled.qty ||
                    !permissionChecker('update_putaway_qty') ||
                    scanningType === 'solo'
                  "
                  :onError="formError.qty"
                  :onEnter="shiftInputFocus"
                />
                <input-field
                  ref="location"
                  id="location"
                  @update-value="updateValue"
                  type="textfield"
                  label="Location"
                  required
                  :disabled="isDisabled.location"
                  :onError="formError.location"
                  :onEnter="handleLocationInput"
                />
              </v-col>
            </v-row>
          </div>
        </div>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <!-- <button
            type="reset"
            class="btn btn-light px-5 py-3 ls1"
            @click="toggleModal"
          >
            Cancel
          </button> -->
          <button
            v-if="scanningType !== 'tote'"
            ref="submit"
            type="submit"
            class="btn btn--print-upload px-5 py-3 ls1"
            @click.prevent="previous"
            :disabled="!enablePrevious"
          >
            Previous
          </button>
          <button
            ref="submit"
            type="submit"
            class="btn btn-info px-5 mx-5 py-3 ls1"
            @click.prevent="submitForm"
          >
            Add
          </button>
          <button
            v-if="scanningType !== 'tote'"
            ref="submit"
            type="submit"
            class="btn btn--print-upload px-5 py-3 ls1"
            @click.prevent="next"
            :disabled="!enableNext"
          >
            Next
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import InputField from "@/own/components/InputField.vue";
import {
  SET_PUTAWAY_MODAL_TABLE_DATA,
  ADJUST_PUTAWAY_MODAL_TABLE_DATA,
  UPDATE_TABLE_DATA,
  ADJUST_PUTAWAY_TASK_DATA,
  GET_PUTAWAY_TASK_DATA,
  SET_PUTAWAY_ITEM_FOR_ACTION,
} from "@/core/services/store/putawayTasks.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "ScannerModal",

  components: { InputField },

  data: () => ({
    formData: {
      tote_id: null,
      sku: null,
      qty: 0,
      location: null,
    },
    showModal: false,
    skipCurrentTask: true, // need to skip forward 1 setp if item is erased
    putawayData: null,
    storeIndex: null,
    formError: {
      tote_id: null,
      sku: null,
      qty: null,
      locatin: null,
    },
    isDisabled: {
      sku: true,
      qty: true,
      location: true,
    },
  }),

  methods: {
    toggleModal() {
      if (this.showModal) {
        this.clearFields();
      }
      this.showModal = !this.showModal;
    },
    submitForm() {
      //check required fields status
      const FIELDS = ["tote", "location"];

      this.scanningType === "bulk" && FIELDS.push("quantity");

      if (this.scanningType === "tote") {
        this.formData = {
          ...this.formData,
          sku: this.sku,
          qty: this.quantity,
        };
      }

      FIELDS.forEach((item) => {
        this.$refs[item].checkErrorStatus();
      });

      // check if all required fields are valid
      const valid = Object.values(this.formData).every(
        (value) => value !== null
      );

      // check if form data similar to show response
      const putawayItem = this.putawayTaskItemData.putaway_task;

      if (valid) {
        if (this.formData.tote_id !== putawayItem.tote_id) {
          this.formError.tote_id = "This tote is invalid";
        } else this.formError.tote_id = null;
        if (this.formData.sku !== putawayItem.sku.sku) {
          this.formError.sku = "This sku is invalid";
        } else this.formError.sku = null;

        if (this.formData.qty < 1) {
          this.formError.qty = "Invalid quanity";
        } else if (this.formData.qty > putawayItem.expected_qty) {
          this.formError.qty = "Quantity exceeds limit";
        } else this.formError.qty = null;

        if (!Object.values(this.formError).every((value) => !value)) return;

        let nextTaskId = this.nextItem?.id;
        this.storeIndex = this.items.indexOf(this.putAwayItemForAction);
        // const isNextAvailable = !!this.nextItem.id;

        setTimeout(() => {
          this.$store.commit(SET_PAGE_LOADING, true);
        }, 100);

        // payload for request
        const payload = {
          id: this.putawayTaskItemData.putaway_task.id,
          ...this.formData,
        };

        // submit form data
        new Promise(() => {
          ApiService.post(`/warehouse_management/putaway_tasks/update`, payload)
            .then(() => {
              const duplicateIndex = this.putawayModalTableData.findIndex(
                (item) =>
                  item.tote_id === payload.tote_id &&
                  item.sku === payload.sku &&
                  item.location === payload.location
              );
              if (duplicateIndex === -1) {
                this.$store.commit(SET_PUTAWAY_MODAL_TABLE_DATA, payload);
              } else {
                this.$store.commit(ADJUST_PUTAWAY_MODAL_TABLE_DATA, {
                  index: duplicateIndex,
                  payload,
                });
              }
              this.$store.commit(ADJUST_PUTAWAY_TASK_DATA, payload.qty);
              if (this.scanningType === "tote") {
                Swal.fire({
                  title: `Updated`,
                  text: `Putaway updated successfully`,
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.toggleModal();
                this.$emit("navigate-to-next");
                return;
              }
              //
              Swal.fire({
                title: `Updated`,
                text: `Putaway updated successfully`,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.clearFiledExceptTote();
                if (this.putawayTaskItemData.putaway_task.expected_qty) {
                  return;
                } else if (
                  this.putawayTaskItemData.putaway_task.expected_qty === 0 &&
                  !nextTaskId
                ) {
                  this.$emit("update-item-index", this.storeIndex);
                  this.toggleModal();
                  this.$emit("navigate-to-next");
                } else {
                  Swal.fire({
                    title: `Forwading to the next..`,
                    icon: "info",
                    showConfirmButton: false,
                    timer: 1500,
                  })
                    .then(() => {
                      this.skipCurrentTask = false;
                    })
                    .then(() => {
                      this.$store.commit(SET_PAGE_LOADING, true);
                      this.$store.dispatch(GET_PUTAWAY_TASK_DATA, {
                        id: nextTaskId,
                      });
                      //move putawayItemForAction for action
                      const index = this.items.findIndex(
                        (index) => index.id === nextTaskId
                      );
                      this.$store.commit(SET_PUTAWAY_ITEM_FOR_ACTION, index);
                    })
                    .then(() => {
                      this.skipCurrentTask = true;
                    })
                    .then(() => {
                      setTimeout(() => {
                        this.$refs.sku.$refs.sku.focus();
                      }, 200);
                    });
                }
              });
            })
            .finally(() => {
              this.$store.dispatch(UPDATE_TABLE_DATA, {
                warehouse_id: this.selectedWarehouse,
                ...this.tableState,
              });
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        });
      }
    },
    clearFields() {
      this.formData = {
        tote_id: null,
        sku: null,
        qty: 0,
        location: null,
      };
      const FIELDS = ["tote", "location"];
      if (this.scanningType !== "tote") {
        FIELDS.push("sku", "quantity");
      }

      FIELDS.forEach((item) => {
        this.$refs[item].clearField();
      });

      this.isDisabled = { sku: true, qty: true, location: true };
    },
    clearFiledExceptTote() {
      this.formData = {
        ...this.formData,
        sku: null,
        qty: 0,
        location: null,
      };
      const FIELDS = ["location"];
      if (this.scanningType !== "tote") {
        FIELDS.push("sku", "quantity");
      }

      FIELDS.forEach((item) => {
        this.$refs[item].clearField();
      });

      this.isDisabled = { sku: false, qty: true, location: true };
      setTimeout(() => {
        this.$refs.sku.$refs.sku.focus();
      }, 200);
    },
    updateValue(value, field) {
      this.formData[field] = value;
    },
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    shiftInputFocus(id) {
      const parentComponent = ["tote", "sku", "quantity", "location"];
      const inputs = ["tote_id", "sku", "qty", "location"];
      let index = inputs.findIndex((item) => item === id);
      // skip quantity input if user have no permission
      if (index === 1) {
        if (!this.permissionChecker("update_putaway_qty")) {
          index += 1;
        }
      }
      const focusParent = parentComponent[index + 1];
      const focusInput = inputs[index + 1];

      this.$nextTick(() => {
        setTimeout(() => {
          if (index < 3) {
            this.$refs[focusParent].$refs[focusInput].focus();
          } else {
            this.submitForm();
          }
        });
      });
    },
    handleToteInput() {
      if (
        this.formData.tote_id !== this.putawayTaskItemData.putaway_task.tote_id
      ) {
        Swal.fire({
          title: "The given data was invalid",
          text: "Tote does not exists",
          icon: "error",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonColor: "#f64e5f",
        });
        this.$refs.tote.clearField();
        this.formData.tote_id = null;
        this.isDisabled.sku = true;
        this.formData.sku = null;
        this.$refs.sku.clearField();
      } else {
        if (this.scanningActionType === "tote") {
          this.isDisabled.location = false;
          setTimeout(() => {
            this.$refs.location.$refs.location.focus();
          });
        } else {
          this.isDisabled.sku = false;
          this.shiftInputFocus("tote_id");
        }
      }
    },
    handleSkuInput() {
      if (this.formData.sku !== this.sku) {
        Swal.fire({
          title: "Warning",
          text: "You scanned different SKU",
          icon: "warning",
          showConfirmButton: true,
        });
        this.$refs.sku.clearField();
        this.formData.sku = null;
      } else {
        if (this.formData.qty < this.quantity) {
          this.formData.qty = Number(this.formData.qty) + 1;
          this.$refs.quantity.setValue(this.formData.qty);
          this.isDisabled.qty = false;
          this.isDisabled.location = false;
        } else {
          Swal.fire({
            title: "Warning",
            text: "You have reached the expected quantity",
            icon: "warning",
          });
        }
      }
    },
    handleLocationInput() {
      if (
        !this.isLocationOverwrite &&
        this.formData.location !==
          this.putawayTaskItemData.putaway_task.suggested_location.name
      ) {
        Swal.fire({
          title: "Warning",
          text: "You have entered a different location",
          icon: "warning",
        });
      } else {
        this.shiftInputFocus("location");
      }
    },
    previous() {
      if (this.previousItem) {
        //move putawayItemForAction for action
        const index = this.items.findIndex(
          (index) => index.id === this.previousItem.id
        );
        this.$store.commit(SET_PUTAWAY_ITEM_FOR_ACTION, index);
        // fetch the next available data
        this.$store.commit(SET_PAGE_LOADING, true);
        this.$store.dispatch(GET_PUTAWAY_TASK_DATA, {
          id: this.previousItem.id,
        });
        // this.$emit("navigate-to-previous");
      }
    },
    next() {
      if (this.nextItem) {
        const nextItemId = this.nextItem.id;
        // fetch the next available data
        this.$store.commit(SET_PAGE_LOADING, true);
        this.$store.dispatch(GET_PUTAWAY_TASK_DATA, { id: nextItemId });
        //move putawayItemForAction for action
        const index = this.items.findIndex((index) => index.id === nextItemId);
        this.$store.commit(SET_PUTAWAY_ITEM_FOR_ACTION, index);
        // this.$emit("navigate-to-next");
      }
    },
  },

  computed: {
    putAwayItemForAction: function () {
      return this.$store.getters.getPUTAWAYITEMForAction;
    },
    putawayTaskItemData: function () {
      return this.$store.getters.getPUTAWAYTASKItemData;
    },
    putawayModalTableData: function () {
      return this.$store.getters.getPUTAWAYModalTableData;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    table_permissions: function () {
      if (this.$store.getters.getPUTAWAYTASKSTablePermissions) {
        return this.$store.getters.getPUTAWAYTASKSTablePermissions.map(
          function (perm) {
            return perm.name;
          }
        );
      } else {
        return false;
      }
    },
    scanningType: function () {
      return this.$store.getters.getPUTAWAYScanningActionType;
    },
    suggestedLocation() {
      return this.putawayTaskItemData?.putaway_task?.suggested_location;
    },
    sku() {
      return this.putawayTaskItemData?.putaway_task?.sku?.sku;
    },
    tote() {
      return this.putawayTaskItemData?.putaway_task?.tote_id;
    },
    quantity() {
      return this.putawayTaskItemData?.putaway_task?.expected_qty;
    },
    scanningActionType() {
      return this.$store.getters.getPUTAWAYScanningActionType;
    },
    header: function () {
      return this.scanningType === "tote" ? "Scan tote" : "Scan putaway";
    },
    isLocationOverwrite: function () {
      return this.putawayTaskItemData.putaway_task.is_overwrite;
    },
    skuDescription: function () {
      return this.putawayTaskItemData?.putaway_task?.sku?.description;
    },
    putawaytaskTableData() {
      return this.$store.getters.getPUTAWAYTASKSTableData.data;
    },
    nextItem: function () {
      // find current item index
      const index = this.putawaytaskTableData.findIndex(
        (item) => item.id === this.putawayTaskItemData?.putaway_task?.id
      );
      //find next pending or partially completed item
      return this.putawaytaskTableData
        .slice(this.skipCurrentTask ? index + 1 : index)
        .find(
          (item) =>
            (item.status === "Pending" ||
              item.status === "Partially Completed") &&
            item.tote_id === this.putawayTaskItemData?.putaway_task?.tote_id
        );
    },
    enableNext: function () {
      return this.nextItem && this.formData.tote_id && !this.formData.sku;
    },
    previousItem: function () {
      // find current item index
      const index = this.putawaytaskTableData.findIndex(
        (item) => item.id === this.putawayTaskItemData?.putaway_task?.id
      );
      //find previous pending or partially completed item
      return this.putawaytaskTableData
        .slice(0, index)
        .findLast(
          (item) =>
            (item.status === "Pending" ||
              item.status === "Partially Completed") &&
            item.tote_id === this.formData.tote_id
        );
    },
    enablePrevious: function () {
      return this.previousItem && this.formData.tote_id && !this.formData.sku;
    },
    items: function () {
      return this.$store.getters.getPUTAWAYTASKSTableData?.data;
    },
    tableState: function () {
      return this.$store.getters.getPUTAWAYTASKSTableState;
    },
  },

  mounted() {},
};
</script>
