<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Easy putaway</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="bg-secondary rounded p-6 mb-3">
          <v-row>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded mt-0">
                <v-col cols="auto" class="font-weight-black py-0 pr-0">
                  SKU:
                </v-col>
                <v-col class="py-0">{{
                  serverData?.putaway_task?.sku?.sku || "Unknown"
                }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Expected Quantity:
                </v-col>
                <v-col>{{
                  serverData?.putaway_task?.expected_qty || "Unknown"
                }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="bg-secondary rounded p-6 mb-8">
          <v-row>
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Tote:
                </v-col>
                <v-col>
                  <div>
                    {{ serverData?.putaway_task?.tote_id || "Unknown" }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Suggested Location:
                </v-col>
                <v-col>
                  <div>
                    {{
                      serverData?.putaway_task?.suggested_location?.name ||
                      "Unknown"
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12">
            <v-text-field
              ref="tote"
              v-model="formData.tote_id"
              label="Tote"
              clearable
              type="text"
              outlined
              dense
              append-icon="mdi-barcode-scan"
              :error-messages="toteErrors"
              @input="$v.formData.tote_id.$touch()"
              @blur="$v.formData.tote_id.$touch()"
              @keydown.enter="$refs.sku.focus()"
            ></v-text-field>
            <v-text-field
              ref="sku"
              v-model="formData.sku"
              label="SKU"
              clearable
              type="text"
              outlined
              dense
              :disabled="!formData.tote_id"
              append-icon="mdi-barcode-scan"
              :error-messages="skuErrors"
              @input="$v.formData.sku.$touch()"
              @blur="$v.formData.sku.$touch()"
              @keydown.enter="handleSkuInput"
            ></v-text-field>
            <v-text-field
              ref="qty"
              v-model="formData.qty"
              label="Quantity"
              clearable
              type="number"
              outlined
              dense
              :disabled="!serverData"
              append-icon="mdi-barcode-scan"
              :error-messages="quantityErrors"
              @input="$v.formData.qty.$touch()"
              @blur="$v.formData.qty.$touch()"
              @keydown.enter="$refs.location.focus()"
            ></v-text-field>
            <v-text-field
              ref="location"
              v-model="formData.location"
              label="Location"
              clearable
              type="text"
              outlined
              dense
              :disabled="!serverData || !formData.qty"
              append-icon="mdi-barcode-scan"
              :error-messages="locationErrors"
              @input="$v.formData.location.$touch()"
              @blur="$v.formData.location.$touch()"
              @keydown.enter="handleLocationInput"
            ></v-text-field>
          </v-col>
        </v-row>
        <!--end::Body-->
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            ref="submit"
            type="reset"
            class="btn btn--export-filter px-5 py-3 mx-8"
            @click="submit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "EasyPutawayModal",
  mixins: [validationMixin],
  props: ["refresher"],
  validations: {
    formData: {
      tote_id: { required },
      sku: { required },
      qty: { required },
      location: { required },
    },
  },
  components: {},
  data: () => ({
    dialog: false,
    formData: {
      tote_id: null,
      sku: null,
      qty: 0,
      location: null,
    },
    serverData: null,
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.clearFields();
      } else {
        setTimeout(() => {
          this.$refs.tote.focus();
        }, 100);
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    handleSkuInput() {
      const payload = {
        warehouse_id: this.selectedWarehouse,
        show_type: "easy",
        tote_id: this.formData.tote_id,
        sku: this.formData.sku,
      };

      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(`/warehouse_management/putaway_tasks/show`, payload)
        .then(({ data }) => {
          this.serverData = data;
          this.formData.qty = 1;
          setTimeout(() => {
            this.$refs.qty.focus();
          }, 150);
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    handleLocationInput() {
      if (
        !this.serverData.putaway_task.is_overwrite &&
        this.formData.location !==
          this.serverData.putaway_task.suggested_location.name
      ) {
        Swal.fire({
          title: "Warning",
          text: "You have entered a different location",
          icon: "warning",
        });
      } else {
        setTimeout(() => {
          this.$refs.submit.focus();
        }, 100);
      }
    },
    clearFields() {
      this.$v.$reset();
      this.formData = {
        tote_id: null,
        sku: null,
        qty: 0,
        location: null,
      };
      this.serverData = null;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.formData.qty > this.serverData.putaway_task.expected_qty) {
        Swal.fire({
          title: "Warning",
          text: "Quantity limit exceeds.",
          icon: "warning",
        });
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);

      const payload = {
        id: this.serverData.putaway_task.id,
        ...this.formData,
      };
      ApiService.post(`/warehouse_management/putaway_tasks/update`, payload)
        .then(() => {
          Swal.fire({
            title: `Updated`,
            text: `Putaway updated successfully`,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
          this.refresher();
          this.toggleModal();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    toteErrors() {
      return this.handleFormValidation("tote_id");
    },
    skuErrors() {
      return this.handleFormValidation("sku");
    },
    quantityErrors() {
      return this.handleFormValidation("qty");
    },
    locationErrors() {
      return this.handleFormValidation("location");
    },
  },
};
</script>
