<template>
  <div class="p-4 bg-white rounded-lg">
    <DataTable v-if="isTableDataLoaded && !hideTableContent" />
    <h1
      v-else-if="!isTableDataLoaded && hideTableContent && !pageLoading"
      class="text-center grey--text lighten-5 mt-20"
    >
      No data available
    </h1>
  </div>
</template>

<script>
import DataTable from "@/own/components/warehouseManagement/putawayTasks/DataTable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/putawayTasks.module";
import Swal from "sweetalert2";

export default {
  name: "PutawayTasks",
  components: { DataTable },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
    },
    store_names: {
      getTableData: "getPUTAWAYTASKSTableData",
      getTableProperties: "getPUTAWAYTASKSTableProperties",
    },
    warehouse_id: null,
  }),
  methods: {
    noWarehouseSelectedWarning() {
      Swal.fire({
        title: `Warning`,
        text: `Please select an warehouse to proceed with putaway tasks.`,
        icon: "warning",
        showConfirmButton: true,
      });
    },
    async updateTableData() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        warehouse_id: this.selectedWarehouse,
      });
    },
  },

  computed: {
    isTablePropertiesLoaded: function () {
      return !!this.$store.getters[this.store_names.getTableProperties];
    },
    isTableDataLoaded: function () {
      return !!this.$store.getters.getPUTAWAYTASKSTableData;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    hideTableContent: function () {
      return (
        (this.isTablePropertiesLoaded && !this.isTableDataLoaded) ||
        (this.isTablePropertiesLoaded && !this.selectedWarehouse)
      );
    },
    pageLoading: function () {
      return this.$store.getters.getPageLoading;
    },
  },

  async beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});

    if (!this.selectedWarehouse) {
      this.noWarehouseSelectedWarning();
      this.$store.commit(SET_PAGE_LOADING, false);
    } else {
      this.updateTableData();
    }
  },
  watch: {
    selectedWarehouse() {
      if (this.selectedWarehouse) {
        this.updateTableData();
      }
    },
  },
};
</script>
<style scoped>
.row-bg-color {
  background-color: #eef0f8;
}

.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}
.custom-container-height::-webkit-scrollbar {
  width: 12px !important;
}
.custom-container-height::-webkit-scrollbar-thumb {
  border-radius: 12px !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}
</style>
